import React from 'react';
import { PlatformTable, Section } from '../../../../components';

const SectionPlatformsDesign = () => {
  return (
    <Section title="Platforms">
      <PlatformTable />
    </Section>
  );
};

export default SectionPlatformsDesign;
