import React from 'react';
import { Paragraph, Section, SectionSubhead } from '../../../../components';

const SectionUsage = () => {
  return (
    <Section title="Usage">
      <SectionSubhead>Linked Avatars</SectionSubhead>
      <Paragraph>
        Don’t give the avatar a link that doesn’t make sense, like pizzahut.com.
        The user avatar should go to a user’s profile, not the team’s, and vice
        versa.
      </Paragraph>
    </Section>
  );
};

export default SectionUsage;
