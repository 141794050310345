import React from 'react';
import { PageNavigation } from '../../../../components';

const SectionPageNav = () => {
  return (
    <PageNavigation
      links={['Style', 'Sizes', 'Usage', 'Accessibility', 'Platforms']}
    />
  );
};

export default SectionPageNav;
